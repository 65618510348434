
/*about*/

/*banner*/
.about-banner-section {
    position: relative;
    width: 100%;
    text-align: center;
    min-height: 650px;
    margin: 0 auto;
}

.about-banner-section .content {
    padding-top: 130px;
    margin: 0 auto;
    max-width: 1300px;
    padding-left: 80px;
    padding-right: 80px;
}

.about-banner-section .content .header {
    text-align: right;
    font: normal normal bold 60px/70px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
    max-width: 544px;
    margin-left: auto;
}

.about-banner-section .content hr {
    width: 276px;
    height: 0px;
    border: 3px solid #B3290C;
    opacity: 1;
    margin-top: 25px;
    margin-bottom: 25px;
    background: #B3290C;
    margin-left: auto;
}

.about-banner-section .content .info {
    text-align: right;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
    max-width: 544px;
    margin-left: auto;
}

.about-banner-section .content .info .italic {
    font: italic normal normal 15px/19px CVS Health Sans;
    padding-top: 10px;
}

/*about-about*/
.about-about-section {
    background: solid white 0% 0% no-repeat padding-box;
    background-size: cover;
    background-position: center;
    padding-bottom: 80px;
}

.about-about-section .heart-logo {
    width: 82px;
    margin-bottom: 30px;
}

.about-about-section .content {
    padding-top: 100px;
    padding-left: 139px;
    padding-right: 139px;
}

.about-about-section .content .header {
    text-align: left;
    font: normal normal 500 50px/55px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
    max-width: 368px;
}

.about-about-section .content .sub-header {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    font: normal normal bold 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}

.about-about-section .content .info {
    text-align: left;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
}


.about-about-flu-vaccine {
    width: 475px;
    height: 435px;  
}

.about-about-section .about-genetic-tests-content {
    padding-top: 90px;
    width: 100%;
    text-align: center;
}

.about-about-section .about-genetic-tests-content .header {
    width: 100%;
    text-align: center;
}

/*impact section*/
.impact-content-section {
    padding-top: 140px;
    padding-bottom: 100px;
    background-color: #B3290C;
}

.impact-content-section .content {
    background-color: #B3290C;
    padding-left: 0px;
    width: 100%;
    margin: 0;
    max-width: 100%;
}

.impact-content-section .content .header {
    text-align: left;
    font: normal normal 500 60px/70px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    width: 520px;
}

.impact-content-section .content .info {
    text-align: left;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    width: 536px;
}

.impact-content-section .info .italic {
    font: italic normal normal 15px/19px CVS Health Sans;
}

.impact-content-section .impact-graphic {
    width: 89%;
    max-width: 800px;
}

.impact-content-section .twenty-percent {
    text-align: left;
    padding-top: 30px;
    font: normal normal normal 25px/32px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
}

.impact-content-section .twenty-percent .emphasize {
    text-align: left;
    font: normal normal bold 60px/32px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
}

.impact-content-section .twenty-percent .italic {
    margin-top: 10px;
    font: italic normal normal 15px/19px CVS Health Sans;
}

.impact-sub-section {
    padding-top: 130px;
    padding-bottom: 130px;
}

.impact-sub-section .content .info {
    padding-top: 35px;
}

/* how it works section */
.how-it-works-section {
    padding-top: 140px;
    padding-bottom: 100px;
}

.how-it-works-section .content {
    padding-left: 0px;
    width: 100%;
    margin: 0 auto;
    max-width: 1500px;
    padding-left: 139px;
    padding-right: 139px;
}

.how-it-works-section .content hr {
    width: 400px;
    height: 0px;
    border: 3px solid #B3290C;
    background: #B3290C;
    opacity: 1;
    margin-top: 20px;
    margin-bottom: 20px;
}

.how-it-works-section .content .header {
    text-align: left;
    font: normal normal 500 60px/70px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}

.how-it-works-section .content .info {
    text-align: left;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
    max-width: 516px;
}

.how-it-works-section .how-it-works-hero {
    max-width: 451px;
}

@media screen and (max-width: 1200px ) {

    .about-banner-section {
        min-height: 550px;
    }

    .about-banner-section .content {
        width: 100%;
        text-align: center;
        padding-left: 15px;
        padding-bottom: 50px;
        padding-top: 100px;
    }

    .about-banner-section .content .header {
        text-shadow: 0px 0px 25px white;
    }

    .about-banner-section .content .info {
        text-shadow: 0px 0px 25px white;
    }

    .about-banner-section .content hr {
        margin-bottom: 75px;
    }

    .about-about-section .heart-logo {
        margin: 0 auto;
    }
    
    .about-about-section .content {
        width: 100%;
        padding: 15px;
    }

    .about-about-section .content .header {
        width: 100%;
        padding: 15px;
        text-align: center;
    }
    
    .about-about-section .content .do-i-qualify-button {
        margin: 0 auto;
        text-align: center;
    }

    .about-about-section .content .sub-header {
        text-align: center;
    }

    .about-about-section .content .do-i-qualify-button {
        padding-bottom: 25px;
    }

    .about-about-section .impact-content {
        padding-top: 80px;
    }

    .about-about-section .impact-content .usa-graphic {
        margin: 0 auto;
    }

    .about-about-section .impact-content .twenty-percent {
        text-align: center;
        margin: 0 auto;
        padding-bottom: 50px;
    }

    .about-about-section .study-schedule {
        padding-top: 80px;
    }

    .about-about-section .study-schedule .study-schedule-picture {
        margin: 0 auto;
    }

    .impact-content-section {
        padding-top: 80px;
    }

    .impact-content-section .impact-graphic {
        width: 80%;
        margin: 0 auto;
    }

    .impact-content-section .content .header {
        text-align: center;
        margin: 0 auto;
        width: 100%;
    }

    .impact-content-section .content .info {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        padding: 25px;
    }

    
    .how-it-works-section {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .how-it-works-section hr {
        width: 100%;
    }

    .how-it-works-section .content .header {
        text-align: center;
        margin: 0 auto;
        width: 100%;
    }

    .how-it-works-section .content hr {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 25px;
        width: 100%;
    }


}

@media screen and (max-width: 576px) {

    .about-banner-section .content {
        padding-left: 25px;
        padding-right: 25px;
    }

    .about-banner-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;
        text-align: center;
    }

    .about-banner-section .content .info {
        text-align: center;
        margin: 25px;
    }

    .about-banner-section .content .header hr {
        width: 100%;
        margin: 25px;
    }

    .about-genetic-tests-section .content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .about-about-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;
    }

    .impact-content-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;
    }

    .about-genetic-tests-section .content .header {
        width: 100%;
    }

    .about-genetic-tests-section .content .info {
        width: 100%;
    }

    .how-it-works-section .content {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    .how-it-works-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;

    }
}