/*FAQ*/
.faq-banner-section {
    position: relative;
    width: 100%;
    text-align: center;
    min-height: 254px;
}


.faq-banner-section .content {
    padding-top: 90px;
    margin: 0 auto;
    width: 838px;
}

.faq-banner-section .content .header {
    text-align: center;
    font: normal normal bold 60px/70px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}


/*faq about*/
.faq-about-section {
    background: solid white 0% 0% no-repeat padding-box;
    background-size: cover;
    background-position: center;
    padding-bottom: 80px;
}

.faq-about-section .content {
    padding-top: 100px;
    padding-left: 139px;
    padding-right: 139px;
}

.faq-about-section .content .header {
    text-align: left;
    font: normal normal 500 50px/55px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}

.faq-about-section .content .info {
    text-align: left;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
    width: 536px;
}


.faq-about-section .how-to-participate-content .header {
    text-align: left;
    font: normal normal medium 60px/70px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}

.faq-about-section .how-to-participate-content .info {
    padding-top: 25px;
    text-align: left;
    font: normal normal normal 25px/32px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}

.faq-about-section .how-to-participate-content .info .list {
    margin-left: 25px;
}

.faq-about-section .content .learn-more-button {
    width: 150px;
}

.faq-about-flu-vaccine {
    max-width: 544px;
}

.click-to-learn-more {
    width: 318px;
    cursor: pointer;
}

.faq-items {
    padding-top: 65px;
}

.faq-items .faq-header .ant-collapse-header {
    background: #b3290c;
    color: white !important;
    font: normal normal bold 25px/32px CVS Health Sans;
    margin-top: 25px;
}

.faq-items .faq-header .ant-collapse-content-box {
    background: #e9e9e9;
    font: normal normal normal 20px/27px CVS Health Sans;
    padding: 25px !important;
}

.faq-items .faq-header .ant-collapse-content-box .italic {
    font: italic normal normal 15px/22px CVS Health Sans;
}

.faq-items .faq-header .ant-collapse-header img {
    filter: invert(100%) sepia(10%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);

}



@media screen and (max-width: 1200px ) {
    
    .faq-about-flu-vaccine {
        padding-top: 25px;
        margin: 0 auto;
        text-align: center;
    }
    
    .faq-banner-section .content {
        width: 100%;
    }

    .faq-banner-section .content .header {
        width: 100%;
    }

    .faq-about-section .content {
        width: 100%;
        padding: 0;
        padding-top: 50px;
        margin: 0 auto;
        text-align: center;
    }

    .faq-about-section .content .header {
        width: 100%;
    }

    .faq-about-section .content .info {
        padding-top: 40px;
        width: 100%;
    }

    .faq-about-section .content .ant-col {
        width: 100%;
    }

    .faq-about-section .content .ant-col > * {
        text-align: center;
        margin: 0 auto;
    }

    .faq-about-flu-vaccine {
        width: 100%;
    }  
}

@media screen and (max-width: 576px) {
    
    .faq-banner-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;
        padding-bottom: 25px;
    }

    .faq-about-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;
        padding: 10px;
    }
    
    .faq-items .faq-header .ant-collapse-header {
        font: normal normal bold 20px/30px CVS Health Sans;
    }

    .faq-items .faq-header .ant-collapse-content-box {
        font: normal normal normal 21px/25px CVS Health Sans;
    }

    .faq-items .faq-header .ant-collapse-content-box .italic {
        font: italic normal normal 12px/19px CVS Health Sans;
    }
}