/*ADOBE STYLE*/
:root {
    /* Colors: */
    --unnamed-color-b4280c: #B4280C;
    --unnamed-color-707070: #707070;
    --unnamed-color-010000: #010000;
    --unnamed-color-ffffff: #FFFFFF;
    
    /* Font/text values */
    --unnamed-font-family-cvs-health-sans: CVS Health Sans;
    --unnamed-font-style-normal: normal;
    --unnamed-font-weight-normal: normal;
    --unnamed-font-weight-medium: medium;
    --unnamed-font-weight-bold: bold;
    --unnamed-font-size-18: 18px;
    --unnamed-font-size-25: 25px;
    --unnamed-font-size-60: 60px;
    --unnamed-character-spacing-0: 0px;
    --unnamed-line-spacing-25: 25px;
    --unnamed-line-spacing-32: 32px;
    --unnamed-line-spacing-70: 70px;
}

/**Font**/
@font-face {
    font-family: "CVS Health Sans";
    src: url(CVSHealthSans-Regular.woff2);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "CVS Health Sans";
    src: url(CVSHealthSans-Bold.woff2);
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: "CVS Health Sans";
    src: url(CVSHealthSans-Medium.woff2);
    font-style: normal;
    font-weight: 500;
}


html {
    width: 100% !important;
    height: initial !important;
    margin: 0px !important;
    padding: 0px !important;
    overflow-x: hidden; 
}

body {
    width: 100% !important;
    height: initial !important;
    margin: 0px !important;
    padding: 0px !important;
    overflow-x: hidden; 
}

/*nav bar*/
#navbar {
    font: normal normal normal 16px/21px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    border-bottom: 2px solid #B3290C;
}

#navbar ul {
    align-items: center;
}

#navbar .conversion-button {
    width: 110px;
}

.logo {
    width: 280px;
}
/*footer*/
.footer-section {
    background: #E9E9E9 0% 0% no-repeat padding-box;
    opacity: 1;
}

.footer-section .content {
    min-height: 190px;
    padding-top: 44px;
    padding-left: 139px;
    padding-right: 139px;
}

.footer-section .content .header {
    text-align: left;
    font: normal normal normal 25px/32px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}


.footer-section .content .info {
    padding-top: 21px;
    text-align: left;
    font: normal normal normal 14px/19px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}

.footer-section .content .social {
    margin-left: 20px;
    text-align: left;
    padding-top: 41px;
    padding-bottom: 25px;
    font: normal normal normal 14px/19px CVS Health Sans;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-left: auto;
}

.footer-section .content .flex {
    flex-direction: row;
}

.footer-section .content .social {
    text-align: center;
    font: normal normal bold 12px/15px CVS Health Sans;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    opacity: 1;
}

.footer-bottom {
    background: #333333 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    min-height: 178px;
}

.footer-bottom .content {
    padding-top: 25px !important;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    
    text-decoration: underline;
    font: normal normal normal 12px/15px CVS Health Sans;
    justify-items: initial;    
    flex-direction: row;
}

.footer-bottom .copyright {
    text-align: left;
    font: normal normal normal 12px/15px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.footer-bottom .nabp {
    margin: 0 auto;
}

/* Global class names */

.content {
    max-width: 1366px;
    margin: 0 auto;
}

.click-to-learn-more {
    width: 318px;
    cursor: pointer;
}

.do-i-qualify-button {
    width: 148px;
    cursor: pointer;
}

.enroll-today-button {
    text-align: center;
    font: normal normal 500 18px/24px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: #B4280C 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    cursor: pointer;
    width: 150px;
}

.enroll-today-button-inverted {
    text-align: center;
    font: normal normal 500 18px/24px CVS Health Sans;
    letter-spacing: 0px;
    color: #B4280C;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    cursor: pointer;
    width: 150px;
}

.conversion-button {
    text-align: center;
    font: normal normal 500 18px/24px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: #B4280C 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    cursor: pointer;
    width: 150px;
}

.list {
    margin-left: 25px;
}

.list-content {
    text-align: left;
    margin-left: 25px;
    list-style: disc;
    display: inline-block;
}


/*Questionnaire*/
.progress-wrapper {
    margin: 0 auto !important;
    display: block !important;
}

.progress-container {
    background-color: pink;
}

.progress-bar-completed {
    background-color: green;
}

.progress-10 {
    width: 10%;
}

.progress-20 {
    width: 20%;
}

.progress-30 {
    width: 30%;
}

.progress-40 {
    width: 40%;
}

.progress-50 {
    width: 50%;
}

.progress-60 {
    width: 60%;
}

.progress-70 {
    width: 70%;
}

.progress-80 {
    width: 80%;
}

.progress-90 {
    width: 90%;
}

.white-heart {
    width: 83px;
    margin: 0 auto;
    margin-top: 80px;
}


.footer-section .content .links {
    text-align: right;
    font: normal normal normal 16px/21px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    text-transform: uppercase;
    opacity: 1;
}

.ant-select {
    border: 1px solid black !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: black !important ;
}

.ant-radio-checked .ant-radio-inner:after{
    background-color: black;
}

.ant-radio:hover .ant-radio-inner {
    border-color: black !important;
}

input {
    border: 1px solid black !important;
}

.ant-select-selection-search-input {
    border: none !important;
}


.ant-input-affix-wrapper > input {
    border: none !important;
}

.ant-input-affix-wrapper {
    border-color: black !important;
}

@media screen and (max-width: 1200px ) {
    
    #nav-list {
        margin: 0 auto;
    }

    .logo {
        width: 170px;
    }

    .learn-more-button {
        margin: 0 auto;
        margin-top: 25px;
    }
    
    .about-flu-vaccine {
        padding-top: 25px;
        margin: 0 auto;
        text-align: center;
    }

    .enroll-today-button {
        margin: 0 auto !important;
        margin-bottom: 25px !important;
        text-align: center !important;
    }

    .footer-section .content {
        padding-top: 44px;
        padding: 25px;
    }

    .enroll-today-button-inverted {
        text-align: center;
        margin: 0 auto;
    }

}

@media screen and (max-width: 900px) {

    .footer-bottom .content {
        flex-direction: column;
    }

    .footer-bottom .content .spacer {
        display: none;
    }

    .footer-bottom .content .links {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 576px) {
    
    .do-i-qualify-button {
        margin: 0 auto;
        padding-bottom: 25px;
    }

}

/* Global Animations */
@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0);
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@keyframes newCard {
    from {
        transform: translate3d(0,40px,0);
        background-color: khaki;
    }

    to {
        background-color: none;
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}

@-webkit-keyframes newCard {
    from {
        transform: translate3d(0,40px,0);
        background-color: khaki;
    }

    to {
        background-color: none;
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}
@keyframes landing-fade-delay {
    to {
        opacity: 1;
    }
}

@-webkit-keyframes landing-fade-delay {
    to {
        opacity: 1;
    }
}

.animated {
    animation-duration: 1.0s;
    animation-delay: 0.2s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-fill-mode: both;
    -webkit-animation-delay: 0.2s;
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

.newCard {
    animation-name: newCard;
    -webkit-animation-name: newCard;
}

.landing-fade-delay {
    opacity: 0;
    animation-name: landing-fade-delay;
    animation-delay: 0.4s;
    -webkit-animation-name: landing-fade-delay;
    -webkit-transition: background-image 1.4s ease-in-out;
    transition: background-image 1.4s ease-in-out;
}