@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "CVS Health Sans";
    src: url(styles/CVSHealthSans-Regular.woff2);
    font-style: normal;
    fonr-weight: normal;
}

body {
    font-family: CVS Health Sans;
}
/* 
.ant-btn-primary {
    background: #b3290c 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: none;
    opacity: 1;
    text-transform: uppercase !important;
}

.ant-btn-primary:hover {
    background: #b3290c no-repeat padding-box;
    box-shadow: 0px 0px 0.5px 1px #b3290c;
    border-radius: 10px;
    opacity: 1;
}

.ant-btn-primary:active {
    background:  0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 0.5px 1px hsl(208, 75%, 59%);
    border-radius: 10px;
    opacity: 1;
}

.ant-btn-primary:focus {
    background: hsl(208, 75%, 59%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 0.5px 1px hsl(208, 75%, 59%);
    border-radius: 10px;
    opacity: 1;
} */



@media screen and (min-width: 1200px) {
    .learn-more-clinical-section {
        background: #F1F1F1 0% 0% no-repeat padding-box !important;
    }

    .two-column-layout .ant-row {
        column-count: 2;
        column-width: 250px;
        display: block;
    }


    .clip-nine > .ant-row > .ant-col:nth-child(10) {
        display: inline-block;
    }

    .clip-six > .ant-row > .ant-col:nth-child(7) {
        display: inline-block;
    }
}
