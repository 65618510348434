
/*risk*/
.risk-section {
    min-height: 200px;
    background: #B3290C 0% 0% no-repeat padding-box;
    opacity: 1;
}

.risk-section .content {
    padding-top: 54px;
    max-width: 1088px;
}

.risk-section .content .header {
    text-align: center;
    font: normal normal 500 25px/32px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.risk-section .content .info {
    padding-top: 12px;
    text-align: center;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

@media screen and (max-width: 1200px ) {

    .risk-section .content .info {
        padding-bottom: 45px;
    }

}