
/*banner*/
.banner-section {
    position: relative;
    text-align: center;
    min-height: 651px;
    display: none;
    margin: 0 auto;
    margin-top: 5px;
}

.banner-section .mobile-hero {
    width: 66.6%;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 15px 1px lightgray;
    border-radius: 25px;
}

.banner-section .content {
    padding-top: 20px;
    padding-bottom: 50px;
    margin: 0 auto;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
}

.banner-section .content .header {
    color: black;
    width: 100%;
    font: normal normal 500 30px/35px CVS Health Sans;
    letter-spacing: 0;
}

.banner-section .content .info {
    padding-top: 1.25rem;
    text-align: left;
    color: black;
    font: normal normal normal 23px/30px CVS Health Sans;
    letter-spacing: 0;
}


.banner-section .content .sign-up-link {
    padding-top: 1.25rem;
    letter-spacing: 0;
    margin: 0 auto;
    text-align: center;
}


/*about*/
.about-section {
    background: solid white 0% 0% no-repeat padding-box;
    background-size: cover;
    background-position: center;
    margin-top: 35px !important;
    margin-bottom: 100px !important;
    box-shadow: 0px 2px 15px 1px lightgray;
    border-radius: 25px;
    max-width: 1088px;
    margin: 0 auto;
}

.sign-up-link {
    text-align: left;
    font: normal normal normal 20px/26px CVS Health Sans;
    letter-spacing: 0px;
    color: #BA261A;
    opacity: 1;
    padding-top: 5px;
    text-decoration: underline !important;
    cursor: pointer;
}

.about-section .content {
    padding-top: 78px;
    padding-left: 80px;
    padding-right: 80px;
}

.about-section .content hr {
    width: 375px;
    height: 0px;
    border: 3px solid #B3290C;
    background: #B3290C;
    opacity: 1;
    margin-top: 20px;
    margin-bottom: 20px;
}

.about-section .content .header {
    text-align: left;
    text-align: left;
    font: normal normal bold 30px/35px CVS Health Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 384px;
    margin-bottom: 12px;
}

.about-section .content .info {
    text-align: left;
    font: normal normal normal 14px/19px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
    width: 384px;
}


.about-section .content .info p {
    margin-top: 10px;
    margin-bottom: 10px;
}

.about-section .content .about-photo {
    width: 475px;
}


.about-section .content .info .italic {
    font: italic normal normal 15px/19px CVS Health Sans;
    padding-top: 15px;
}

.about-section .about-genetic-tests-content .header {
    width: 100%;
    text-align: center;
}

/* qualified section */
.qualified-section {
    position: relative;
    text-align: center;
    min-height: 651px;
    max-width: 1366px;
    margin: 0 auto;
    margin-top: 150px;
}

.qualified-section .heart-logo {
    width: 82px;
    margin-bottom: 25px;
}

.qualified-section .content {
    margin-left: 50px;
    margin: 0 auto;
    max-width: 838px;
}

.qualified-section .content .learn-more-button.mobile {
    display: none;
}

.qualified-section .content hr {
    max-width: 800px;
    height: 0px;
    border: 2px solid #B3290C;
    background: #B3290C;
    opacity: 1;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.qualified-section .content .header {
    text-align: left;
    font: normal normal 500 55px/50px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
    margin-bottom: 25px;
}

.qualified-section .content .card .header {
    text-align: center;
    font: normal normal 500 25px/32px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
    margin-bottom: 0px;
}


.qualified-section .content .card .info {
    text-align: center;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}


.qualified-section .content .card hr {
    width: 75px;
    height: 0px;
    border: 3px solid #B3290C;
    background: #B3290C;
    opacity: 1;
    margin: 0 auto;
    margin-top: 10px;
}


.qualified-section .content .card img {
    height: 129.7px;
    margin: 0 auto;
}

.qualified-section .content .info {
    margin: 0 auto;
    text-align: left;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    max-width: 800px;
}

/* how to participate section */
.how-to-participate-section {
    position: relative;
    text-align: center;
    min-height: 651px;
    margin: 0 auto;
    margin-top: 100px;
}

.how-to-participate-section .why-enroll {
    max-width: 1366px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    margin-left: 50px;
    margin: 0 auto;
    max-width: 1066px;
}

.how-to-participate-section .why-enroll .header {
    display: flex;
    flex-wrap: wrap;
}

.how-to-participate-section .why-enroll .header img {
    margin-bottom: 15px;
}


.how-to-participate-section .why-enroll img {
    margin: 0 auto;
}

.how-to-participate-section .heart-logo {
    width: 82px;
    margin-bottom: 25px;
}

.how-to-participate-section .content {
    max-width: initial !important;
}

.how-to-participate-section .content hr {
    width: 100%;
    height: 0px;
    border: 2px solid #B3290C;
    background: #B3290C;
    opacity: 1;
    margin-top: 25px;
    margin-bottom: 50px;
}

.how-to-participate-section .bullet {
    margin-top: 5px;
    height: 20px;
}

.how-to-participate-section .content .header {
    text-align: left;
    text-align: left;
    font: normal normal bold 40px/50px CVS Health Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    display: flex;
    margin-bottom: 50px;
}

.how-to-participate-section .content .card {
    box-shadow: 0px 0px 15px 1px lightgrey;
    border-radius: 25px;
    padding: 38px !important;
    padding-left: 54px !important;
    min-height: 303px;
    max-width: 323px;
    margin: 0 auto;
    background-color: #ffffff;
}

.how-to-participate-section .content .card .header {
    font: normal normal bold 18px/23px CVS Health Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}


.how-to-participate-section .content .card .info {
    text-align: left;
    font: normal normal normal 16px/20px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}


.how-to-participate-section .content .card hr {
    width: 75px;
    height: 0px;
    border: 3px solid #B3290C;
    background: #B3290C;
    opacity: 1;
    margin: 0 auto;
    margin-top: 10px;
}


.how-to-participate-section .content .card img {
    height: 58px;
}

.how-to-participate-section .content .spacer {
    min-height: 50px !important;
    background-color: #f2f2f2;
}

.how-to-participate-section .content .bleed {
    background: #f2f2f2;
    padding-top: 300px;
    height: 500px;
    position: absolute;
    z-index: -1;
    margin-top: -340px;
    width: 100%;
}

.how-to-participate-section .content .info {
    text-align: left;
    font: normal normal normal 25px/32px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}

.how-to-participate-section .content .info .additional-info {
    text-align: left;
    font: normal normal normal 16px/21px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}

.how-to-participate-section .content .enroll-information {
    background-color: #f2f2f2;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 100px;
    margin-bottom: 50px;
}

.how-to-participate-section .content .enroll-information .content {
    max-width: 1066px !important;
    margin: 0 auto;
}

.how-to-participate-section .content .enroll-information .header {
    text-align: left;
    font: normal normal normal 40px/45px CVS Health Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    max-width: 444px;
    margin-bottom: 25px;
}

.how-to-participate-section .content .enroll-information .info {
    text-align: left;
    font: normal normal normal 16px/21px CVS Health Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    max-width: 444px;

}


@media screen and (max-width: 1200px ) {

    .about-section {
        width: 98%;
    }

    .about-section .content {
        padding-top: 55px;
        padding-left: initial;
        padding-right: initial;
        text-align: center;
        margin: 0 auto;
    }

    .about-section .content .header {
        font: normal normal bold 26px/31px CVS Health Sans;
        margin: 0 auto;
        margin-bottom: 12px;
    }

    .about-section .content .info {
        margin: 0 auto;
    }

    .about-section .content .do-i-qualify-button {
        margin-bottom: 40px;
    }

    .about-section .content .ant-col {
        width: 100%;
    }

    .how-to-participate-section .content .header {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 25px;
    }

    .how-to-participate-section .content .info {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    
    .how-to-participate-section .content .enroll-information .sign-up-link {
        margin-bottom: 50px;
    }

    .qualified-section {
        padding: 10px;
    }

    .qualified-section .heart-logo {
        margin: 0 auto;
        padding-bottom: 25px;
    }

    .qualified-section .content .header {
        text-align: center;
    }

    .qualified-section .content .card .info {
        font: normal normal normal 20px/24px CVS Health Sans;
    }

    .qualified-section .content .learn-more-button.mobile {
        display: block;
        margin-bottom: 50px;
    }

    .qualified-section .content .learn-more-button.desktop {
        display: none;
    }

    .how-to-participate-content {
        padding-top: 80px;
    }

    .how-to-participate-hero {
        padding-bottom: 40px;
    }


}

@media screen and (max-width: 990px ) {
    
    
    .how-to-participate-section .content .bleed {
        background: #f2f2f2;
        padding-top: 300px;
        height: 1000px;
        position: absolute;
        z-index: -1;
        margin-top: -1000px;
        width: 100%;
    }
}

@media screen and (max-width: 820px ) {
    
    .about-section {
        width: 97%;
    }

    .about-section .content {
        padding-top: 25px;
        padding-left: initial;
        padding-right: initial;
        text-align: center;
        margin: 0 auto;
    }

    .about-section .content .header {
        font: normal normal bold 26px/31px CVS Health Sans;
        margin: 0 auto;
        margin-bottom: 12px;
        padding-left: 10px;
    }

    .about-section .content .info {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media screen and (max-width: 760px) {

    .banner-section {
        display:block;
    }
    
    .about-section  {
        display: none;
    }
    
}

@media screen and (max-width: 576px) {

    .how-to-participate-section .bullet {
        margin-top: 10px;
        width: 8px;
        height: 8px;
    }

    .how-to-participate-section .content .enroll-information {
        padding: 10px;
    }


    .how-to-participate-section .list {
        margin-left: 0;
    }

    .how-to-participate-section .content .info .additional-info {
        text-align: center;
    }

    .how-to-participate-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;

    }

    .how-to-participate-section .why-enroll {
        padding-left: 5px;
        padding-right: 5px;
    }

    .how-to-participate-section .why-enroll .header {
        font: normal normal 500 30px/35px CVS Health Sans;
    }
}