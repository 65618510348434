
/*learn more clinical section*/
.learn-more-clinical-section {
    min-height: 350px;
}

.learn-more-clinical-section .clinical-hero {
    max-height: 800px;
    margin-left: auto;
}

.learn-more-clinical-section .content {
    padding-left: 100px;
}

.learn-more-clinical-section .learn-more-button-inverted {
    display: none;
    text-align: center;
    font: normal normal 500 18px/24px CVS Health Sans;
    letter-spacing: 0px;
    color: #B4280C;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    cursor: pointer;
    width: 150px;
}

.learn-more-clinical-section hr {
    width: 275px;
    height: 0px;
    border: 3px solid #B3290C;
    background: #B3290C;
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 15px;
}

.learn-more-clinical-section .content .header {
    text-align: left;
    font: normal normal bold 60px/70px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
    max-width: 460px;
    padding-top: 100px;
}

.learn-more-clinical-section .content .info {
    text-align: left;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    max-width: 444px;
}

.learn-more-clinical-section .content .learn-more-button {
    margin-bottom: 100px;
}

/*clinical study process section*/
.clinical-study-process-section {
   padding-top: 100px;
   padding-bottom: 100px; 
}

.clinical-study-process-section .content {
    padding-left: 100px;
    padding-right: 100px;
}

.clinical-study-process-section .content .header {
    text-align: left;
    font: normal normal 500 50px/55px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    text-transform: capitalize;
    opacity: 1;
}

.clinical-study-process-section .content .info {
    text-align: left;
    font: normal normal normal 18px/25px CVS Health Sans;
    letter-spacing: 0px;
    color: #010000;
    opacity: 1;
}

.clinical-study-process-section hr {
    width: 100%;
    height: 0px;
    border: 3px solid #B3290C;
    background: #B3290C;
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 15px;
}


@media screen and (min-width: 1200px) {
    .learn-more-clinical-section {
        background: #F1F1F1 0% 0% no-repeat padding-box !important;
    }
}


@media screen and (max-width: 1200px ) {

    .learn-more-clinical-section .content .header {
        font: normal normal bold 70px/80px CVS Health Sans;
        text-shadow: 0px 0px 25px white;
    }

    .learn-more-clinical-section .content .info {
        font: normal normal normal 21px/28px CVS Health Sans;
        text-shadow: 0px 0px 25px white;
    }

    .learn-more-clinical-section .learn-more-button {
        display: none;
    }

    .learn-more-clinical-section .learn-more-button-inverted {
        display: block;
    }

    .clinical-research-participate-section .content {
        margin: 0 auto;
    }

    .clinical-research-participate-section .content .participate-hero {
        margin: 0 auto;
    }
}

@media screen and (max-width: 576px) {


    .learn-more-clinical-section .content  {
        margin: 0 auto;
        padding: 0;
    }

    .learn-more-clinical-section .content hr  {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 25px;
    }

    .learn-more-clinical-section .content .learn-more-button-inverted  {
        margin: 0 auto;
        margin-bottom: 25px;
    }

    .learn-more-clinical-section .content .header {
        font: normal normal 500 55px/65px CVS Health Sans;
        text-align: center;
        padding: 25px;
        margin: 0 auto;
    }

    .learn-more-clinical-section .content .info {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 auto;
    }

    .clinical-research-participate-section .content .participate-hero {
        width: 80%;
    }

    .clinical-research-participate-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;
    }

    .clinical-research-participate-section .content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .clinical-research-participate-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;
        text-align: center;
    }
    
    .clinical-study-process-section .content {
        margin: 0 auto;
        padding-left: 10px;
        padding-right: 10px;
    }

    .clinical-study-process-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;
        text-align: center;
    }
}