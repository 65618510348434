
/*join-us*/
.join-us-section {
    position: relative;
    width: 100%;
    text-align: center;
    min-height: 501px;
    max-width: 1088px;
    margin: 0 auto;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 50px;
}

.join-us-section .conversion-button {
    text-align: center;
    font: normal normal 500 18px/24px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: #B4280C 0% 0% no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    cursor: pointer;
    width: 150px;
    margin: 0 auto;
    margin-top: 50px;
}

.join-us-section .content {
    padding-top: 140px;
    margin: 0 auto;
    width: 1000px;
    border-radius: 25px;
    box-shadow: 0px 2px 15px 1px lightgray;
    background-color: #ffffff;
    padding-bottom: 130px;
}

.join-us-section .content .header {
    text-align: center;
    font: normal normal 500 50px/55px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 25px;
    max-width: 752px;
    margin: 0 auto;
}

.join-us-section .content .sub-header {
    text-align: center;
    font: normal normal 500 30px/39px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    margin-bottom: 25px;
    max-width: 752px;

}

.join-us-section .content .info {
    text-align: center;
    font: normal normal normal 25px/32px CVS Health Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    max-width: 752px;
    margin: 0 auto;
}

@media screen and (max-width: 1200px ) {
    
    .join-us-section .content {
        width: 100%; 
    }

}

@media screen and (max-width: 576px) {

    .join-us-section .content {
        padding-top: 80px;
    }

    .join-us-section .content .header {
        font: normal normal 500 45px/55px CVS Health Sans;
    }

    .join-us-section .content {
        padding-top: 140px;
        padding-bottom: 80px;
    }
}
