.header-section {
    background-repeat:no-repeat;
    margin: 0 auto;
    background-size: cover !important;
    background-position: center !important;
    opacity: 1;
    min-height: 333px;
    max-width: 1088px;
    margin-top: 40px;
    border-radius: 25px;
    margin-bottom: 25px;
}

.header-section .content {
    padding-top: 104px;
    margin: 0 auto;
}

.header-section .content .header {
    margin: 0 auto;
    text-align: center;
    font: normal normal normal 40px/51px CVS Health Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 10px;
}

.header-section .content .conversion-button {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.add-another {
    width: 250px !important;
}

.active-trials {
    width: 190px !important;
}

.header-section .content .info {
    margin: 0 auto;
    max-width: 936px;
    text-align: center;
    font: normal normal normal 16px/21px CVS Health Sans;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

@media screen and (max-width: 760px) {
    
.header-section .content {
    padding: 5px;
    padding-top: 25px;
    margin: 0 auto;
}
}